<template>
    <b-navbar>
        <div class="col-lg-2"></div>
        <div class="col-lg-4">
            <h1>
                <img
                    src="../../../public/holeffect_logo.svg"
                    class="img-fluid logo-size"
                />
                <span class="custom-color-primary">HOL</span
                ><span class="custom-color-secondary">EFFECT</span>
            </h1>
        </div>
        <div class="col-lg-6">
            <ul class="nav nav-tabs">
                <li :class="style.helice" v-on:click="goHelices">
                    <a class="nav-link" href="#">Liste Hélices</a>
                </li>
                <li :class="style.group" v-on:click="goGroup">
                    <a class="nav-link" href="#">Groupe Hélices</a>
                </li>
                <li :class="style.cloud" v-on:click="goCloud">
                    <a class="nav-link" href="#">Cloud séq</a>
                </li>
                <li :class="style.creator" v-on:click="goCreator">
                    <a class="nav-link" href="#">3D Création</a>
                </li>
                <li :class="style.stats" v-on:click="goStats">
                    <a class="nav-link" href="#">Stats</a>
                </li>
            </ul>
        </div>
    </b-navbar>
</template>
<script>
export default {
    props: {
        active: {
            required: true,
            type: String,
        },
    },
    data: function () {
        return {
            user: {},
            style: {
                helice: { "nav-item-active": false },
                group: { "nav-item-active": false },
                cloud: { "nav-item-active": false },
                creator: { "nav-item-active": false },
                stats: { "nav-item-active": false },
            },
        };
    },

    async beforeMount() {
        this.style[this.active]["nav-item-active"] = true;
    },
    methods: {
        goHelices: function () {
            this.$router.push({ name: "HomeAdmin" });
        },
        goGroup: function () {
            this.$router.push({ name: "GroupsAdmin" });
        },
    },
};
</script>
<style>
.nav-tabs {
    border-bottom: 1px solid #11a9b9;
}
.nav-link {
    color: #0e476b;
}
.nav-link:hover {
    color: #0e476b;
}
.nav-item {
    border: 1px solid #11a9b9;
}
.nav-item-active {
    border: 1px solid #11a9b9;
    background: #11a9b9;
}
.logo-size {
    width: 8%;
}
</style>
