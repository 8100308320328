<template>
    <div class="container-fluid">
        <Navbar active="group" />
        <div class="overflow-hidden p-0 cover">
            <b-modal v-model="createGroupModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Ajouter un groupe
                        </h2>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6 class="custom-color-secondary">Nom du groupe</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-input
                            v-model="groupName"
                            placeholder="Nom"
                        ></b-form-input>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h6 class="custom-color-secondary">
                            Sélectionner un utilisateur
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-select
                            :options="userOption"
                            v-model="selectUser"
                        ></b-form-select>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="createGroup"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openCreateGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>

            <b-modal v-model="deleteGroupModal" hide-header hide-footer>
                <div class="row mt-4 text-center">
                    <div class="col-lg-12">
                        <h2 class="custom-color-secondary">
                            Supprimer un groupe
                        </h2>
                    </div>
                </div>
                <div class="row mt-4 text-center text-center">
                    <div class="col-lg-12">
                        <p>
                            Attention, vous allez supprimer le groupe
                            <b class="text-danger"
                                >{{ groupSelect.idAdvertiser }} -
                                {{ groupSelect.groupName }}</b
                            >
                        </p>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-primary btn-size"
                            v-on:click="deleteGroup"
                            >Supprimer</b-button
                        >
                    </div>
                    <div class="col-lg-6">
                        <b-button
                            class="custom-btn-color-secondary btn-size"
                            v-on:click="openDeleteGroupModal"
                            >Annuler</b-button
                        >
                    </div>
                </div>
            </b-modal>
            <div class="row mt-4">
                <div class="col-lg-5">
                    <table class="table table-striped custom-bordered">
                        <thead>
                            <tr>
                                <td scope="col"></td>
                                <td>Groupe</td>
                                <td>Détail</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label class="container">
                                        <input
                                            type="radio"
                                            v-model="groupSelect"
                                            value="0"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td></td>
                                <td>Non groupé</td>
                            </tr>
                            <tr v-for="group of groupes" :key="group.idGroup">
                                <td>
                                    <label class="container">
                                        <input
                                            type="radio"
                                            v-model="groupSelect"
                                            :value="group"
                                        />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td>{{ group.idGroup }}</td>
                                <td>{{ group.groupName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-4">
                    <table class="table table-striped custom-bordered">
                        <thead>
                            <tr>
                                <td>Contenu du groupe</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="helice of helices.data"
                                :key="helice.idDevice"
                            >
                                <td>
                                    <div class="row text-center">
                                        <div class="col-lg-2">
                                            <label class="container">
                                                <input
                                                    type="radio"
                                                    v-model="heliceCheck"
                                                    :value="helice.idDevice"
                                                />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-lg-10 text-left">
                                            {{ helice.idDevice }} -
                                            {{ helice.DeviceName }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-3">
                    <b-card-group deck>
                        <b-card
                            header-class="custom-card-header"
                            class="custom-card-body"
                        >
                            <template #header> Opérations </template>
                            <b-card-body class="reduce-padding">
                                <div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="openCreateGroupModal"
                                    >
                                        <span class="text-white pointer">
                                            Créer un groupe
                                        </span>
                                        <span class="text-danger">
                                            (groupe vide)
                                        </span>
                                    </div>
                                </div>
                                <div class="row text-left">
                                    <div
                                        class="col-lg-12"
                                        v-on:click="openDeleteGroupModal"
                                    >
                                        <span class="text-white pointer">
                                            Supprimer
                                        </span>
                                        <span class="text-danger">
                                            (supprimer l'hélice selectionnée)
                                        </span>
                                    </div>
                                </div>
                            </b-card-body></b-card
                        ></b-card-group
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar";
export default {
    data: function () {
        return {
            groupes: [],
            checkAll: false,
            checkTab: {},
            groupSelect: {},
            helices: [],
            users: [],
            createGroupModal: false,
            selectUser: "",
            userOption: [],
            currentUser: "",
            deleteGroupModal: false,
        };
    },
    components: {
        Navbar,
    },
    async beforeMount() {
        this.currentUser = this.$store.state.user.user;
        this.users = await this.$store.dispatch("admin/getAllUser");
        for (let user of this.users) {
            if (user.Name != this.currentUser.username) {
                this.userOption.push({
                    text: user.idAdvertiser + " - " + user.Name,
                    value: user.Name,
                });
            } else {
                this.userOption.push({
                    text: user.idAdvertiser + " - " + "Moi",
                    value: user.Name,
                });
            }
        }
        this.groupes = await this.$store.dispatch("admin/getAllGroupList");
    },
    methods: {
        changeCurrentGroup: async function () {
            this.helices = await this.$store.dispatch("group/getDeviceGroup", {
                groupId: this.editCheck,
            });
        },
        openCreateGroupModal: async function () {
            this.createGroupModal = !this.createGroupModal;
        },
        createGroup: async function () {
            await this.$store.dispatch("group/createGroup", {
                userName: this.selectUser,
                groupName: this.groupName,
            });
            this.groupes = await this.$store.dispatch("admin/getAllGroupList");
            this.openCreateGroupModal();
        },
        openDeleteGroupModal: async function () {
            this.deleteGroupModal = !this.deleteGroupModal;
        },
        deleteGroup: async function () {
            await this.$store.dispatch("group/deleteGroup", {
                userName: this.currentUser.username,
                groupId: this.groupSelect.idGroup,
                groupName: this.groupSelect.groupName,
            });
            this.groupes = await this.$store.dispatch("admin/getAllGroupList");
            this.openDeleteGroupModal();
        },
    },
    watch: {
        checkAll: {
            handler() {
                this.checkedAll();
            },
            deep: true,
        },
        editCheck: {
            handler() {
                if (this.editCheck !== "") {
                    this.changeCurrentGroup();
                }
            },
        },
    },
};
</script>
<style scoped>
.custom-card-header {
    color: #0e476b;
    background-color: white;
}
.custom-card-body {
    background-color: #11a9b9;
    border: 2px solid #11a9b9;
}
.reduce-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.btn-size {
    padding: 7px 15px;
}
</style>
